import React, { useState } from "react"
import { slide as Menu } from "react-burger-menu"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
//import useScrollLock from '../../custom/use-scroll-lock'

import Div100vh from "react-div-100vh"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import logoBrown from "../../images/logos/saven_logo_brown.svg"
import link from "../../images/social/link-dark.svg"
import face from "../../images/social/face-dark.svg"
import insta from "../../images/social/insta-dark.svg"
import mobileMenu from "../../images/mobile-menu.svg"
import mobileMenuWhite from "../../images/mobile-menu-white.svg"

const Sidebar = ({ brown }) => {
  const { languages, changeLanguage } = useI18next()
  const { t } = useTranslation()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  //const [ lock, setLock ] = useState(false)

  //useScrollLock(lock)

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
    //setLock(!lock)
  }

  return (
    <StyledBar brown={brown}>
      <div className="header">
        <button className="btn-header" onClick={toggleSidebar}>
          <div className="hamburguer-icon">
            <div>
              <img
                src={brown ? mobileMenuWhite : mobileMenu}
                alt="menu-icon"
                className="menu-icon"
              />
            </div>
          </div>
          <h3 className={font.aGGD}>{t("sidebar:menu.title")}</h3>
        </button>
      </div>
      <Menu
        customBurgerIcon={false}
        isOpen={sidebarOpen}
        width={"100%"}
        noOverlay
      >
        <div className={font.aGGB + " langGroup"}>
          {languages.map((lng, i) => (
            <button
              onClick={e => {
                e.preventDefault()
                changeLanguage(lng)
              }}
            >
              {lng}
              {i !== lng.length && "\u00a0|\u00a0"}
            </button>
          ))}
        </div>
        <div className="links">
          {t("sidebar:menu.links", { returnObjects: true }).map(
            ({ link, linkTxt }, i) => (
              <Link to={link} key={"menuV" + i} activeClassName="red">
                <h4 className={font.nSBI + " linksP"}>{linkTxt}</h4>
              </Link>
            )
          )}
        </div>
        <div className="header-footer">
          <Link to="/" className="logo-link">
            <img src={logoBrown} alt="Logo da Saven" className="sidebarLogo" />
          </Link>
          <div className="segment">
            <div className="social">
              <a
                href="https://www.linkedin.com/company/luacheiavv"
                className="socialLink"
                target="_blank"
                rel="noreferrer"
              >
                <img src={link} alt="Logo do LinkedIn" className="logoSmall" />
              </a>
              <a
                href="https://www.facebook.com/LuaCheiaSaven"
                className="socialLink"
                target="_blank"
                rel="noreferrer"
              >
                <img src={face} alt="Logo do Facebook" className="logoSmall" />
              </a>
              <a
                href="https://www.instagram.com/luacheia_saven"
                className="socialLink"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={insta}
                  alt="Logo do Instagram"
                  className="logoSmall"
                />
              </a>
            </div>
            <div className="address">
              {t("footer:address.links", { returnObjects: true }).map(
                ({ link, linkTxt }, i) => (
                  <a
                    href={link}
                    key={"addressLink" + i}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p className={font.aGGB}>{linkTxt}</p>
                  </a>
                )
              )}
            </div>
          </div>
        </div>
      </Menu>
    </StyledBar>
  )
}

export default Sidebar

const StyledBar = styled(Div100vh)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: auto !important;

  .header {
    display: flex;
    align-items: center;
    background-color: ${props => (props.brown ? "#150e03" : "#fff")};
    border-bottom: ${props =>
      props.brown ? "1px solid #fff" : "1px solid #3e3028"};
    padding: 0 25px;
    height: 50px;

    .btn-header {
      width: 100%;
      display: flex;
    }

    h3 {
      margin-left: auto;
      color: ${props => (props.brown ? "#fff" : "#3e3028")};
      letter-spacing: 0.5px;
      font-size: 15px;
    }
  }

  .bm-menu {
    padding: 25px 25px;
    background: #fff;
  }

  .bm-menu-wrap {
    height: calc(100vh - 50px) !important;
  }

  .bm-item-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .links {
    color: #3e3028;
    margin-top: 3vh;

    .red {
      color: #fa4f4f;
    }

    h4 {
      font-size: 33px;
    }
  }

  .logo-link {
    width: fit-content;
    margin-left: auto;

    .sidebarLogo {
      width: 100px;
    }
  }

  .segment {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 25px;

    .social {
      display: flex;
      flex-wrap: wrap;
    }

    .social a {
      padding: 5px 15px 0 0;
    }

    .address a {
      text-align: right;
    }
  }

  .header-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .langGroup {
    /* opacity:0;
      pointer-events:none; */
    display: flex !important;
    width: fit-content;
    color: #3e3028;
    margin-left: auto;

    button {
      font-family: "AGGB";
      text-transform: uppercase;
      font-size: 15px;
    }

    &:focus {
      outline: 0;
    }
  }
`
