import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"

import Div100vh from "react-div-100vh"
import styled, { css } from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import logo from "../../images/logos/saven_logo.svg"
import logoBrown from "../../images/logos/saven_logo_brown.svg"

const Sidebar = ({ white, switchMenu, menuBtn }) => {
  const { languages, changeLanguage } = useI18next()
  const { t } = useTranslation(["sidebar"])

  const [menu, setMenu] = useState(switchMenu)

  function switcher() {
    if (menu === "left") {
      document.querySelector(".select").style.left = "22px"
      setMenu("right")
    } else {
      document.querySelector(".select").style.left = "2px"
      setMenu("left")
    }
  }

  return (
    <StyledBar white={white} menu={menu} menuBtn={menuBtn}>
      <Link to="/">
        <img
          src={!white ? logo : logoBrown}
          alt="Logo da Saven"
          className="sidebarLogo"
        />
      </Link>
      <div className="menu-container">
        {menuBtn && (
          <Button
            switcher
            white={white}
            className="switcher"
            onClick={switcher}
          >
            <div className="select" />
          </Button>
        )}
        <div className="menu">
          <CSSTransition
            in={menu === "left"}
            timeout={350}
            classNames="switch"
            unmountOnExit
          >
            <div className="menuP">
              {menuBtn && (
                <p className={font.aGGD + " menuTitle"}>{t("menu.title")}</p>
              )}
              <div className="links">
                {t("menu.links", { returnObjects: true }).map(
                  ({ link, linkTxt }, i) => (
                    <Link to={link} key={"menuV" + i} activeClassName="red">
                      <h4 className={font.nSBI + " linksP"}>{linkTxt}</h4>
                    </Link>
                  )
                )}
              </div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={menu === "right"}
            timeout={350}
            classNames="switch"
            unmountOnExit
          >
            <div className="menuV">
              <p className={font.aGGD + " menuTitle"}>{t("vinhos.title")}</p>
              <div className="docs">
                <div className="links">
                  <p className={font.aGGD + " menuTitle"}>
                    {t("vinhos.docs.title")}
                  </p>
                  {t("vinhos.docs.links", { returnObjects: true }).map(
                    ({ link, linkTxt }, i) => (
                      <Link
                        to={link}
                        key={"menuV" + i}
                        partiallyActive={true}
                        activeClassName="red"
                      >
                        <h4 className={font.nSBI}>{linkTxt}</h4>
                      </Link>
                    )
                  )}
                </div>
              </div>
              <div className="reg">
                <div className="links">
                  <p className={font.aGGD + " menuTitle"}>
                    {t("vinhos.regionais.title")}
                  </p>
                  {t("vinhos.regionais.links", { returnObjects: true }).map(
                    ({ link, linkTxt }, i) => (
                      <Link
                        to={link}
                        key={"menuV" + i}
                        partiallyActive={true}
                        activeClassName="red"
                      >
                        <h4 className={font.nSBI}>{linkTxt}</h4>
                      </Link>
                    )
                  )}
                </div>
              </div>
              <div className="reg">
                <div className="links">
                  <p className={font.aGGD + " menuTitle"}>
                    {t("vinhos.outros.title")}
                  </p>
                  {t("vinhos.outros.links", { returnObjects: true }).map(
                    ({ link, linkTxt }, i) => (
                      <Link
                        to={link}
                        key={"menuV" + i}
                        partiallyActive={true}
                        activeClassName="red"
                      >
                        <h4 className={font.nSBI}>{linkTxt}</h4>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
      <div className={font.aGGB + " langGroup"}>
        {languages.map((lng, i) => (
          <button
            onClick={e => {
              e.preventDefault()
              changeLanguage(lng)
            }}
          >
            {lng}
            {i !== lng.length && "\u00a0|\u00a0"}
          </button>
        ))}
      </div>
    </StyledBar>
  )
}

export default Sidebar

const StyledBar = styled(Div100vh)`
  position: fixed;
  width: 15.65%;
  background: #150e04;
  color: #fff8f4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3.13vw;
  z-index: 1;
  min-width: 200px;
  ${props =>
    props.white &&
    css`
      background: white;
    `}

  .menu-container {
    height: ${props => props.menuBtn && "100%"};

    @media only screen and (max-height: 1110px) {
      overflow-y: auto;
    }
  }

  @media only screen and (max-height: 1110px) {
    .menu-container::-webkit-scrollbar {
      width: 3px;
      background-color: #3e3028;
    }
    .menu-container::-webkit-scrollbar-thumb {
      height: 6px;
      background-clip: padding-box;
      background-color: #fff;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .menu-container::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    .menu-container::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .sidebarLogo {
    width: 100%;
  }

  .switcher {
    position: relative;
    margin: 7vh auto 10px;
  }

  .select {
    position: absolute;
    top: 2px;
    left: ${props => (props.menu === "left" ? "2px" : "22px")};
    height: 24px;
    width: 24px;
    background: ${props => (props.white === "isWhite" ? "#3e3028" : "#fff9f4")};
    border-radius: 100%;
    transition: left 0.35s;
  }

  .menu {
    position: relative;
    .menuV {
      position: absolute;
      top: 0;
      width: 100%;
    }
    margin-bottom: 115px;
  }

  .menuTitle {
    font-size: 14px;
    color: #71584a;
    text-align: center;
  }

  .links {
    margin-top: ${props => props.menuBtn && "7vh"};
    position: relative;
    color: ${props => (props.white === "isWhite" ? "#3e3028" : "#fff8f4")};
    .red {
      color: #fa4f4f;
    }
    .menuTitle {
      text-align: unset;
      position: absolute;
      top: -2em;
    }
    h4 {
      font-size: 20px;
    }
  }

  .linksP {
    margin: 1vh 0;
  }

  /* react-transition-group */
  .switch-enter {
    opacity: 0;
  }
  .switch-enter-active {
    opacity: 1;
    transition: opacity 350ms ease-out;
  }
  .switch-exit {
    opacity: 1;
  }
  .switch-exit-active {
    opacity: 0;
    transition: opacity 350ms ease-out;
  }

  .langGroup {
    /* opacity:0;
      pointer-events:none; */
    display: flex;
    color: ${props => (props.white === "isWhite" ? "#3e3028" : "#fff8f4")};

    button {
      font-family: "AGGB";
      text-transform: uppercase;
      font-size: 15px;
    }
  }
`
