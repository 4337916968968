import styled, { css } from "styled-components"

export const Button = styled.button`
  background: transparent;
  border-radius: 2px;
  border: 1px solid #fff;
  user-select: none;
  outline: none;
  cursor: pointer;

  ${props => props.regular && css`
    width: 327px;
    border: 1px solid #3e3028;
    border-radius: 2px;
    height: 3.5em;
  `}

  ${props => props.styleless && css`
    background: unset;
    border-radius: unset;
    border: unset;
  `}
  ${props => props.fullHeight && css`
    height: 100%;
  `}

  ${props => props.filterSelection && css`
    display: inline-block;
    text-align: left;
    color: #fff9f4;
    text-align: center;
    padding: 10px 15px;
    transition: all 0.3s;
    :hover, &.selec {
      background-color: #fff9f4;
      color: #3e3028;
    }
  `}

  ${props => props.asLink && css`
    display: inline-block;
    border: none;
    text-align: left;
  `}

  ${props => props.switcher && css`
    display: block;
    width: 50px;
    height: 30px;
    border-radius: 25px;
    border-color: #fff9f4;
    border-width: 1px;
  `}

  ${props => props.switcher && props.white && css`
    display: block;
    width: 50px;
    height: 30px;
    border-radius: 25px;
    border-color: #3e3028;
    border-width: 1px;
  `}

  ${props =>
    props.primary &&
    css`
      background: purple;
      color: white;
      width: 200px;
    `}

  ${props =>
    props.projeto &&
    css`
      background: #fff;
      color: black;
      :hover {
        background-color: #1d252c;
        color: #fff;
      }
    `}
`
