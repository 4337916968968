export const device = {
  mobile: '(min-width: 300px)',
  mobileXL: '(min-width: 350px)',
  mobile400: '(min-width: 400px)',
  tablet: '(min-width: 570px)',
  tabletM: '(min-width: 750px)',
  tabletXL: '(min-width: 900px)',
  desktop: '(min-width: 1024px)',
  desktopM: '(min-width: 1200px)',
  desktopL: '(min-width: 1300px)',
  desktopXL: '(min-width: 2000px)'
}